import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import PriceSelect from './price-select'
import { CDN_IMG } from '../../../../../constants/endpoints'
import { BASE_PRODUCT_ID_AND_YOTPO_PRODUCT_ID_MAP } from '../../../../../constants/yotpo'
import ProvenButton from '../../../../shared/proven-button'
import { trackEvent } from '../../../../../utils/analytics'
import YotpoStars from '../../../yotpo-stars'
import useWidth from '../../../../../hooks/useWidth'
import { validateEmail } from '../../../../../utils/helpers'

import './styles.scss'
import { getEmailVerification } from '../../../../../actions/quiz.actions'

const ProductPurchase = ({ title1, title2, paragraph, notes }) => {
  const [email, setEmail] = useState('')
  const width = useWidth()
  const [isValidEmail, setIsValidEmail] = useState(false)
  const handleJoinWailtList = () => {
    trackEvent('EmailOptIn', {
      email,
      EmailOptIn_page: window.location.href
    })
  }

  //#region useEffect
  useEffect(() => {
    // Validate email input
    const checkEmail = async () => {
      const validation = await validateEmail(email, getEmailVerification)
      setIsValidEmail(validation.result)
    }
    checkEmail()
  }, [email])
  //#endregion

  return (
    <div styleName="product-purchase">
      <div styleName="content">
        <div styleName="header">
          <span styleName="title2">{title2}</span>

          <h1>{title1}</h1>

          <div styleName="sub-header">
            <YotpoStars
              sku_product_id={BASE_PRODUCT_ID_AND_YOTPO_PRODUCT_ID_MAP['eye-cream-duo']}
            ></YotpoStars>
          </div>

          {width !== 'xs' && width !== 'sm' && <hr />}
        </div>

        {width !== 'xs' && width !== 'sm' && (
          <div styleName="paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
        )}

        <div styleName="joinSection">
          <div styleName="emailWrapper">
            <input
              placeholder="Email Address"
              styleName="emailInput"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <ProvenButton
              sm
              green
              textTransformNone
              width="190px"
              text="Join Waitlist"
              disabled={!isValidEmail}
              onClick={handleJoinWailtList}
            />
          </div>
          <div styleName="terms">
            <span>{"By signing up, you agree to PROVEN's "}</span>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <span>{' and '}</span>
            <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>

        <div styleName="price-wrap">
          <PriceSelect
            selected={true}
            label="Subscribe & Save"
            details={`Ships Every 12 Weeks. Cancel Anytime.`}
            offerDetail={'$99 into offer valid 11/24-11/29.'}
            price={99}
            oldPrice={129.99}
            bubbleText="Most Popular"
          />

          <PriceSelect selected={false} price={179.99} />
        </div>

        {(width === 'xs' || width === 'sm') && (
          <div styleName="paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
        )}

        <div styleName="notes">
          <hr />
          <div styleName="note">
            <div styleName="img">
              <img src={`${CDN_IMG}rud/eye-cream-pdp-1-delivery.svg`} alt="deliver icon" />
            </div>
            <p>
              <strong>Estimated Shipping:</strong>
              <span> 2 - 4 days</span>
            </p>
          </div>
          <div styleName="note">
            <div styleName="img">
              <img
                src={`${CDN_IMG}rud/eye-cream-pdp-1-reformulation.svg`}
                alt="reformulation icon"
              />
            </div>
            <p>
              <strong>Free eye cream reformulation,</strong>
              <span> anytime</span>
            </p>
          </div>
          <hr />
        </div>

        <div styleName="sub-notes">{notes}</div>
      </div>
    </div>
  )
}

ProductPurchase.propTypes = {
  title1: propTypes.string,
  title2: propTypes.string,
  paragraph: propTypes.string,
  notes: propTypes.string
}

export default ProductPurchase
