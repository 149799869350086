import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { createProvenTheme } from '../../../styles/theme-proven'

const themeProven = createProvenTheme()

function LabelWithBannerNew({ children }) {
  const bannerSx = {
    fontSize: '13px !important',
    fontFamily: '"Lexend", sans-serif',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '160%',
    letterSpacing: 0,
    color: `${themeProven.palette.gray.elysian} !important`
  }

  return (
    <Stack direction="row" sx={{ gap: themeProven.spacing(1) }}>
      <Box
        sx={{
          backgroundColor: 'secondary.main',
          borderRadius: themeProven.spacing(0.5),
          padding: '1px 6px'
        }}
      >
        {/* TODO add font as typografy.js variant once we refactor ProvenMenuItem since it overrides styles */}
        <Typography sx={bannerSx}>NEW</Typography>
      </Box>
      {children}
    </Stack>
  )
}
export default LabelWithBannerNew
